import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import InnerHero from '../components/InnerHero';

const Employers = () => (
  <StaticQuery
    query={graphql`
      query EmployersQuery {
        allContentfulEmployers {
          edges {
            node {
              hero {
                file {
                  url
                }
              }

              tagline

              topTextArea {
                content {
                  content {
                    value
                  }
                }
              }

              topImage {
                file {
                  url
                }
              }

              middleTagline

              middleTextArea {
                content {
                  content {
                    value
                  }
                }
              }

              middleImage {
                file {
                  url
                }
              }

              bottomTagline

              industries {
                services {
                  icon
                  title
                  desc
                  fields
                }
              }

              pageTitle

              meta {
                meta {
                  name
                  content
                  description
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const graphqlData = data.allContentfulEmployers.edges[0].node;

      return (
        <Layout>
          <SEO
            title={graphqlData.pageTitle}
            lang="en"
            meta={graphqlData.meta.meta}
          />

          <InnerHero
            image={graphqlData.hero.file.url}
            tagline={graphqlData.tagline}
          />

          <div className="container--md">
            <section id="upper" className="row">
              <div className="col-12 col-sm-12 col-md-5 col-lg-5 hidden--xs hidden--sm">
                <img
                  src={graphqlData.topImage.file.url}
                  alt="We find you candidates"
                />
              </div>
              <div className="col-12 col-sm-12 col-md-1 col-lg-1" />
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 align--center">
                <div
                  dangerouslySetInnerHTML={{
                    __html: graphqlData.topTextArea.content[0].content[0].value,
                  }}
                />
              </div>
            </section>

            <br />
          </div>
          <div className="bg--upendoLight">
            <div className="container--md">
              <section id="middle" className="row padding--md">
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 padding--lg font--26 text--center">
                  <strong>{graphqlData.middleTagline}</strong>
                </div>
                <div
                  id="middle"
                  className="col-12 col-sm-12 col-md-6 col-lg-6 align--center"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        graphqlData.middleTextArea.content[0].content[0].value,
                    }}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                  <img
                    src={graphqlData.middleImage.file.url}
                    alt="Personalized Candidates"
                  />
                </div>
              </section>
            </div>
            <br />
          </div>

          <div className="container--md">
            <br />
            <section id="lower" className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 text--center font--26">
                <strong>{graphqlData.bottomTagline}</strong>
                <br />
                <br />
              </div>
              {graphqlData.industries.services.map((service) => (
                <div
                  key={service.title}
                  className="col-12 col-sm-12 col-md-6 col-lg-4 row"
                >
                  <div className="col-12 text--center">
                    <div className="upendo--icon margin--auto">
                      <i
                        className={`${service.icon} magin--auto font--upendoSecondary`}
                      />
                    </div>
                  </div>
                  <div className="col-12 padding--md text--center">
                    <strong className="font--18">{service.title}</strong>
                    <br />
                    <br />
                    {/* <br />
                    <div className='font--12 font--paragraph'>
                      {service.desc}
                      <ul style={{ paddingLeft: 35, lineHeight: '21px' }}>
                        { service.fields.map((field) => (
                          <li key={field} className='font--12'>
                            {field}
                          </li>
                        ))}
                      </ul>
                    </div> */}
                  </div>
                </div>
              ))}
            </section>
          </div>
          <br />
          <div className="container--md">
            <div className="row">
              <div className="col-12">
                {graphqlData.meta.meta[0].description}
              </div>
            </div>
          </div>
          <br />
        </Layout>
      );
    }}
  />
);

export default Employers;
